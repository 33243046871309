import React from "react";
import SpectrumSection from "../containers/SpectrumSection";
import RoadmapSection from "../containers/RoadmapSection";
import CTASection from "../containers/CTASection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="Roadmap"
      pathname="/roadmap"
      description="Learn useful info about our progress on features request and shape the future of VisualEyes. Our community consists of creative designers like you."
    >
      <RoadmapSection />
      <SpectrumSection />
      {/* <CTASection title="Be part of the future" /> */}
    </MainLayout>
  );
};
