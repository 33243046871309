import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Image from "../../components/Image";
import data from "./data.json";

import {
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden
} from "./timeline.style";
import SectionLayout from "../../components/SectionLayout";

const Label = styled.span`
  background-color: transparent;
  ${props =>
    props.soon &&
    `
      background-color: rgb(62,33,222,0.1);
      color: #3e21de;
  `}
  ${props =>
    props.live &&
    `
      color: #1C905F;
      background-color: #1C905F22;
  `}
  ${props =>
    props.beta &&
    `
      color: #ed5a02;
      background-color: #ed5a0222;
  `}
      
  letter-spacing: 1px;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 1rem;
  font-size: 10px;
  font-weight: bold;
`;

function getBadge(status) {
  switch (status) {
    case "live":
      return <Label live>LIVE</Label>;
    case "soon":
      return <Label soon>COMING SOON</Label>;
    case "beta": {
      return <Label beta>BETA</Label>;
    }
  }
}

const TimelineSection = ({
  row,
  col,
  textArea,
  imageArea,
  imageTwo,
  indexStyle,
  timelineTitle,
  timelineDescription
}) => {
  return (
    <SectionLayout
      title="Our Roadmap"
      description="The future of VisualEyes is shaping with the help of designers like you"
      sectionId="roadmap_section"
    >
      <Box {...row}>
        <Box {...col} {...imageArea}>
          <Fade bottom>
            <Image
              filename={"/roadmap.png"}
              alt="Illustration of our upcoming features"
            />
          </Fade>
        </Box>
        <Box {...col} {...textArea}>
          <TimelineWrapper>
            {data.timeline.map((item, index) => (
              <TimelineItem key={`timeline-item-${index}`}>
                <TimelineIndex>
                  <Hidden>
                    <Slide bottom>
                      <Text
                        as="span"
                        content={item.index || `0${index + 1}`}
                        {...indexStyle}
                      />
                    </Slide>
                  </Hidden>
                </TimelineIndex>
                <TimelineContent>
                  <Hidden>
                    <Slide bottom delay={100}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start"
                        }}
                      >
                        <Heading
                          as="h2"
                          content={item.title}
                          {...timelineTitle}
                        />
                        {getBadge(item.status)}
                      </div>
                    </Slide>
                  </Hidden>
                  <Hidden>
                    <Slide bottom delay={200}>
                      <Text
                        content={item.description}
                        {...timelineDescription}
                      />
                    </Slide>
                  </Hidden>
                </TimelineContent>
                <TimelineDot />
              </TimelineItem>
            ))}
          </TimelineWrapper>
        </Box>
      </Box>
    </SectionLayout>
  );
};

TimelineSection.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object
};

TimelineSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-16px",
    mr: "-16px",
    alignItems: "center"
  },
  imageAreaRow: {
    flexDirection: "row-reverse"
  },
  col: {
    pr: "16px",
    pl: "16px"
  },
  textArea: {
    width: ["100%", "100%", "55%", "50%", "55%"],
    mb: ["40px", "40px", "0", "0", "0"]
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "45%"],
    mb: ["30px", "40px", "40px", "0", "0"]
  },
  title: {
    fontSize: ["30px", "38px", "38px", "48px", "48px"],
    fontWeight: "300",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "16px"
  },
  description: {
    fontSize: "16px",
    color: "textColor",
    lineHeight: "1.75",
    mb: "33px"
  },
  buttonWrapper: {
    mt: ["25px", "50px"],
    flexBox: true,
    justifyContent: "center"
  },
  button: {
    type: "button",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: "46px"
  },
  imageOne: {
    mb: "40px",
    ml: "auto",
    mr: "auto"
  },
  imageTwo: {
    ml: "auto",
    mr: "auto"
  },
  indexStyle: {
    fontSize: ["36px", "42px", "52px", "56px", "72px"],
    fontWeight: "300",
    color: "#eaebec",
    display: "block",
    lineHeight: "1",
    mb: "0"
  },
  timelineTitle: {
    fontSize: ["16px", "17px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "13px"
  },
  timelineDescription: {
    fontSize: ["16px", "16px", "16px", "16px", "16px"],
    lineHeight: "2",
    color: "#5d646d",
    mb: "0"
  }
};

export default TimelineSection;
