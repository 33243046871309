import React from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Image from "../../components/Image";
import Container from "../../components/Container";

import { ButtonGroup } from "../saas.style";

const SpectrumSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  imageArea,
  ImageOne,
  btnStyle,
  outlineBtnStyle
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...imageArea}>
            <Image
              filename={"Spectrum.png"}
              alt="spectrum-logo"
              imgStyle={{ width: "64px", height: "64px" }}
              style={{ width: "64px", height: "64px", margin: "0 auto" }}
            />
          </Box>
          <Box {...textArea}>
            <Heading {...title} content="Join our Spectrum community!" />
            <Text
              {...description}
              content="We are driven by creative designers who want to shape the future of the industry. You can join our community by clicking the button below and suggest new feature recommendations, report bugs or discuss about design in general."
            />
            <ButtonGroup className="button_group">
              <Button
                title="JOIN NOW"
                {...btnStyle}
                onClick={() =>
                  window.open(
                    "https://spectrum.chat/visualeyes-designers?tab=posts"
                  )
                }
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

SpectrumSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object
};

SpectrumSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    className: "trial-section",
    pt: ["20px", "40px", "60px", "80px"],
    pb: ["0px", "0px", "0px", "80px"]
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center"
  },
  textArea: {
    width: ["100%", "100%", "80%", "43%"]
  },
  imageArea: {
    width: ["100%", "100%", "43%"],
    mb: ["35px", "35px", "40px", "40px"]
  },
  title: {
    fontSize: ["32px", "32px", "36px", "42px", "48px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "28px",
    textAlign: "center",
    lineHeight: "1.25"
  },
  description: {
    fontSize: ["16px", "16px", "16px", "16px", "16px"],
    color: "textColor",
    lineHeight: "2.1",
    textAlign: "center",
    mb: ["28px", "28px", "40px", "40px"]
  },
  ImageOne: {
    ml: "auto",
    mr: "auto"
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0f2137"
  }
};

export default SpectrumSection;
